import Loader from "./Loader";
import Typist from "react-typist";
import { CheckIcon } from "@heroicons/react/solid";
import { DuplicateIcon, ExclamationCircleIcon, DownloadIcon } from "@heroicons/react/outline";
import styled, { createGlobalStyle, keyframes } from "styled-components";
import { observer, inject } from "mobx-react";
import { saveAs } from "file-saver";
import CodeEditor from "@uiw/react-textarea-code-editor";
import { useEffect, useState } from "react";
import { io } from "socket.io-client";
import React from "react";

const { connectedSockets, setCurrentSocketId } = require("../utils/sharedSockets");

const GlobalStyle = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400&display=swap');
  
  .uiw-react-textarea-code-editor {
    font-family: 'Roboto', sans-serif !important;
  }
`;

const rippleEffect = keyframes`
  0% {
    background-position: 0px 0px;
  }
  100% {
    background-position: 40px 0px;
  }
`;

const ProgressBarFill = styled.div`
  height: 20px;
  background-color: #4caf50;
  background-image: linear-gradient(90deg, rgba(255, 255, 255, 0.15) 0%, rgba(255, 255, 255, 0.3) 50%, rgba(255, 255, 255, 0.15) 100%);
  background-size: 40px 100%;
  border-radius: 10px;
  transition: width 0.5s ease-in-out;
  animation: ${rippleEffect} 1s linear infinite;
`;

export const Output = inject("store")(
  observer(
    ({
      store,
      title,
      desc,
      Icon,
      output,
      code,
      language,
      outputs,
      loading,
      children,
      fromColor,
      toColor,
      outputsColor,
      OutputsIcon,
      scores,
    }) => {
      const [ws, setWs] = useState(null);
      const [gpt4Data, setGpt4Data] = useState(null);
      const [gpt3TurboData, setGpt3TurboData] = useState(null);
      const [progressData, setProgressData] = useState(null);
      const [runningTotalsData, setRunningTotalsData] = useState(null);
      const [batchData, setBatchData] = useState(null);
      const [editorValue, setEditorValue] = useState("");
      const [showTranscribedPopup, setShowTranscribedPopup] = useState(false);
      const [transcribedText, setTranscribedText] = useState("");
      const [transcriptionNotifications, setTranscriptionNotifications] = useState([]);
      const [error, setError] = useState(null);
      const [isTranscribing, setIsTranscribing] = useState(false);

      const [isMinimized, setIsMinimized] = useState(false);

      // Function to toggle the transcribed text box between minimized and maximized states
      const toggleMinimize = () => {
        setIsMinimized(!isMinimized);
      };

      // Convert newlines in transcribedText to <br /> tags for display
      const formattedTranscribedText = transcribedText.split("\n").map((line, index) => (
        <React.Fragment key={index}>
          {line}
          <br />
        </React.Fragment>
      ));

      useEffect(() => {
        if (typeof gpt4Data === "string") {
          setEditorValue(gpt4Data);
        }
      }, [gpt4Data]);

      function handleEditorChange(e) {
        setEditorValue(e.target.value);
      }

      useEffect(() => {
        const socketUrl = process.env.REACT_APP_SOCKET_URL || "https://server-xsbs.onrender.com:443";
        const socket = io(socketUrl, { secure: socketUrl.indexOf("https") !== -1 });

        socket.on("connect", () => {
          console.log("Connected to Socket.IO server");
          setCurrentSocketId(socket.id);
        });

        socket.on("register_socket", (event) => {
          console.log("Received socket.id from server:", event.socketId);
          connectedSockets[event.socketId] = socket;
          console.log("Current connectedSockets:", connectedSockets);
        });

        socket.on("welcome", (message) => {
          console.log(message);
        });

        socket.on("gpt4", (event) => {
          console.log("internal llm data:", event.data);
          setGpt4Data(event.data);
        });

        socket.on("progress", (event) => {
          console.log("Progress data:", event.data);
          setProgressData(event.data);
        });

        socket.on("running_totals", (event) => {
          setRunningTotalsData(event.data);
        });

        socket.on("batchdata", (event) => {
          setBatchData(event.data);
        });

        socket.on("transcriptionStart", (event) => {
          console.log("Received transcriptionStart event:", event);
          setIsTranscribing(true);
          setTranscriptionNotifications((prevNotifications) => [
            ...prevNotifications,
            { type: "transcriptionStart", message: event.message },
          ]);
        });

        socket.on("transcriptionEnd", (event) => {
          console.log("Received transcriptionEnd event:", event);
          setIsTranscribing(false);
          setTranscriptionNotifications((prevNotifications) => [
            ...prevNotifications,
            { type: "transcriptionEnd", message: event.message },
          ]);
        });

        socket.on("gpt3turbo", (event) => {
          console.log("algorithmic data:", event.data);
          setGpt3TurboData(event.data);
        });

        socket.on("error", (error) => {
          console.error(`Socket.IO error:`, error);
          setIsTranscribing(false);
          displayError(error);
          setProgressData(null); // Add this line to reset the progress data
          setGpt3TurboData(null); // Add this line to reset the gpt3TurboData
        });

        socket.on("disconnect", () => {
          console.log("Client disconnected");
        });

        socket.on("transcribedText", (event) => {
          let transcribedText;

          if (event.data) {
            transcribedText = event.data;
          } else {
            transcribedText = event.transcribedText;
          }

          console.log("Transcribed text data:", transcribedText);
          setTranscribedText(transcribedText);
          setShowTranscribedPopup(true); // Show the popup
        });

        return () => {
          socket.off("transcribedText");
          socket.off("error");
          socket.disconnect();
        };
      }, [setTranscribedText, setShowTranscribedPopup]);

      const displayError = (error) => {
        let errorMessage;

        if (typeof error === "string") {
          errorMessage = error;
        } else if (error.message) {
          errorMessage = error.message;
        } else if (error.error && error.error.message) {
          errorMessage = error.error.message;
        } else {
          errorMessage = "An unknown error occurred";
        }

        // Check for specific error messages
        if (errorMessage.includes("No barcodes found")) {
          errorMessage = "No barcodes found in the uploaded file. Please ensure the file contains barcodes to separate individual essays.";
        } else if (errorMessage.includes("is required")) {
          const field = errorMessage.split(" ")[0];
          errorMessage = `The ${field} is missing. Please provide the ${field} and try again.`;
        } else if (errorMessage.includes("An internal server error occurred")) {
          // This handles the new error message we added in handler.js
          errorMessage = "An internal server error occurred. Our team has been notified. Please try again later.";
        }

        console.error("Error received:", errorMessage); // Add this line for debugging

        setError(errorMessage);
        setIsTranscribing(false);

        // Automatically clear the error after 10 seconds
        setTimeout(() => setError(null), 10000);
      };

      return (
        <>
          <TranscriptionNotifications notifications={transcriptionNotifications} isTranscribing={isTranscribing} />
          <ErrorNotification error={error} />
          {showTranscribedPopup && (
            <div
              className="fixed bottom-0 left-0 m-4 bg-white p-4 rounded shadow-lg z-50 border overflow-hidden"
              style={{ height: isMinimized ? "auto" : "90%", width: isMinimized ? "300px" : "auto" }}
            >
              <div className="flex justify-between items-center">
                <h3 className="text-lg font-bold">Transcribed Essay</h3>
                <div>
                  <button className="mr-3" onClick={toggleMinimize}>
                    {isMinimized ? "Maximize" : "Minimize"}
                  </button>
                  <button onClick={() => setShowTranscribedPopup(false)}>X</button>
                </div>
              </div>
              {!isMinimized && <div className="overflow-auto h-full pb-10">{formattedTranscribedText}</div>}
            </div>
          )}

          {scores && (
            <div className="bg-white rounded-lg shadow-md p-7 mb-10 border border-gray-300">
              <AOScores scores={scores} gpt3TurboData={gpt3TurboData} progressData={progressData} error={error} />
            </div>
          )}
          <div className="relative mb-12">
            <GlobalStyle />
            <div
              className={`absolute inset-0 bg-gradient-to-r from-${fromColor ? fromColor : "green-400"} to-${
                toColor ? toColor : "blue-500"
              } shadow-lg transform md:skew-y-0 md:-rotate-3 md:rounded-3xl -mt-1 md:mt-0`}
            ></div>
            <div className="align-bottom bg-white md:rounded-3xl text-left shadow-xl transform transition-all sm:align-middle transition shadow-md hover:shadow-2xl focus:shadow-2xl">
              <div className="px-6 py-6">
                <div className="sm:flex sm:items-start">
                  {loading && !gpt3TurboData ? (
                    <>
                      <Loader active={loading} className="w-10 h-10" />
                    </>
                  ) : (
                    <>
                      <div
                        className={`mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-${
                          gpt4Data ? "green" : "gray"
                        }-300 sm:mx-0 sm:h-10 sm:w-10 bg-gradient-to-r from-${fromColor ? fromColor : "green-400"} to-${
                          toColor ? toColor : "blue-500"
                        }`}
                      >
                        {Icon ? <Icon className={`h-6 w-6 text-white`} aria-hidden="true" /> : null}
                      </div>
                    </>
                  )}

                  <div className="text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <div as="h3" className="text-lg leading-6 font-medium text-gray-900">
                      {title}
                    </div>
                    <p className="text-sm text-gray-500">{desc}</p>
                  </div>
                </div>
                {gpt4Data ? (
                  <div className="whitespace-pre-wrap min-w-full text-gray-800 h-auto text-lg divide-y px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                    <CodeEditor
                      style={{
                        fontFamily: "Roboto, sans-serif",
                        fontSize: "1.2rem",
                      }}
                      padding={10}
                      value={editorValue}
                      onChange={handleEditorChange}
                    />
                  </div>
                ) : null}

                {gpt4Data && outputs && outputs.length ? (
                  <div className="divide-y divide-dashed divide-gray-300">
                    <div></div>
                    <div></div>
                  </div>
                ) : null}

                {outputs && outputs.length ? <Outputs outputs={outputs} outputsColor={outputsColor} OutputsIcon={OutputsIcon} /> : null}

                {code && code.length ? (
                  <CodeEditor
                    style={{
                      fontFamily: "Roboto, sans-serif",
                      fontSize: "1rem",
                    }}
                    padding={30}
                    language={language}
                    value={code}
                  />
                ) : null}
                <QuickTools outputs={outputs} output={gpt4Data} code={code} />
              </div>
            </div>
          </div>
          {batchData ? (
            <BatchDataBox>
              <>
                {batchData.marked === batchData.total ? (
                  <BatchDataContent>
                    <div>
                      All done! Visit <a href="/history">History</a> to view results
                    </div>
                  </BatchDataContent>
                ) : (
                  <BatchDataContent>
                    <BatchDataItem>
                      <BatchDataLabel>Marked:</BatchDataLabel>
                      <BatchDataValue>{batchData.marked}</BatchDataValue>
                    </BatchDataItem>
                    <BatchDataItem>
                      <BatchDataLabel>Total:</BatchDataLabel>
                      <BatchDataValue>{batchData.total}</BatchDataValue>
                    </BatchDataItem>
                  </BatchDataContent>
                )}
              </>
            </BatchDataBox>
          ) : null}
        </>
      );
    }
  )
);

const ProgressBarContainer = styled.div`
  width: 100%;
  background-color: #e0e0e0;
  border-radius: 10px;
  margin: 10px 0;
`;

function AOScores({ scores, gpt3TurboData, progressData, error }) {
  const [showFinalScore, setShowFinalScore] = useState(false);
  const [progress, setProgress] = useState(0);
  const [finalScoreReady, setFinalScoreReady] = useState(false);

  const calculateProgress = () => {
    if (!progressData || progressData.total === 0) return 0;
    const currentProgress = (progressData.current / progressData.total) * 100;
    return currentProgress;
  };

  useEffect(() => {
    const currentProgress = calculateProgress();

    if (currentProgress < 95 && !finalScoreReady && !error) {
      setProgress(currentProgress);
    } else if (!finalScoreReady && !error) {
      setProgress(95);
    } else if (finalScoreReady && !error) {
      setProgress(100);
      const timer = setTimeout(() => {
        setShowFinalScore(true);
      }, 1000);
      return () => clearTimeout(timer);
    } else if (error) {
      // If an error occurs, set the progress to 0
      setProgress(0);
      setFinalScoreReady(true);
      setShowFinalScore(true);
    }
  }, [progressData, finalScoreReady, error]);

  useEffect(() => {
    if (gpt3TurboData) {
      setFinalScoreReady(true);
    }
  }, [gpt3TurboData]);

  return (
    <div className="aoscores-window">
      <h2 className="text-l mb-3 text-center font-roboto">Numeric Results. Can Take Up To Two Minutes... </h2>

      {!showFinalScore && progressData && !error && (
        <div className="progress-data">
          <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
            <p>
              The grade will be ready in a few moments! {progressData.current} / {progressData.total}
            </p>
            <ProgressBarContainer>
              <ProgressBarFill style={{ width: `${progress}%` }} />
            </ProgressBarContainer>
          </div>
        </div>
      )}

      {showFinalScore &&
        (error ||
          (gpt3TurboData && (
            <div className="gpt3turbo-data">
              {error ? (
                <p>{error}</p>
              ) : gpt3TurboData.totalScore === 0 && Object.keys(gpt3TurboData.assessmentScores).length === 0 ? (
                <p>A potential prompt injection was detected. For security reasons, no feedback or scores have been provided.</p>
              ) : (
                <>
                  <p>Assessment Objective Scores: {gpt3TurboData.assessmentScores.join(", ")}</p>
                  <p>Total Score: {gpt3TurboData.totalScore}</p>
                  <p>Percentage likelihood this response has been AI generated: {gpt3TurboData.percentageLikelihood}%</p>
                </>
              )}
            </div>
          )))}
    </div>
  );
}

export const QuickTools = inject("store")(
  observer(({ store, output, outputs, code }) => {
    const title = output || code || outputs ? "my-output" : "";

    return (
      <>
        {output || code || (outputs && outputs.length) ? (
          <div className="flex">
            <Shortcut
              className="p-1 rounded-lg cursor-pointer hover:bg-green-200 hover:text-green-700 relative group flex flex-col items-center group text-gray-300"
              onClick={() => store.copyToClipboard(output || code || outputs)}
            >
              <DuplicateIcon className="w-10 h-10" />
              <Tooltip className="absolute bottom-2 flex flex-col items-center mb-6 group-hover:flex">
                <span className="relative z-10 p-3 text-sm leading-none text-gray-800 bg-white bg-opacity-25 shadow-lg text-center backdrop-filter backdrop-blur rounded-md">
                  Copy text to clipboard
                </span>
              </Tooltip>
            </Shortcut>
            <Shortcut
              className="p-1 rounded-lg cursor-pointer hover:bg-blue-200 hover:text-blue-700 relative group flex flex-col items-center group text-gray-300"
              onClick={() => {
                const blob = new Blob([output || code || outputs], {
                  type: "text/plain;charset=utf-8",
                });
                saveAs(blob, `${title}.doc`);
              }}
            >
              <DownloadIcon className="w-10 h-10" />
              <Tooltip className="absolute bottom-2 flex flex-col items-center mb-6 group-hover:flex">
                <span className="relative z-10 p-3 text-sm leading-none text-gray-800 bg-white bg-opacity-25 shadow-lg text-center backdrop-filter backdrop-blur rounded-md">
                  Download as Word Document
                </span>
              </Tooltip>
            </Shortcut>
            <div className="flex-1"></div>
            <Shortcut
              className="p-1 rounded-lg cursor-pointer hover:bg-red-200 hover:text-red-700 relative group flex flex-col items-center group text-gray-300"
              onClick={() => store.reportToFeedback(output || code || outputs)}
            >
              <ExclamationCircleIcon className="w-10 h-10" />
              <Tooltip className="absolute bottom-2 flex flex-col items-center mb-6 group-hover:flex">
                <span className="relative z-10 p-3 text-sm leading-none text-gray-800 bg-white bg-opacity-25 shadow-lg text-center backdrop-filter backdrop-blur rounded-md">
                  Report issue with output
                </span>
              </Tooltip>
            </Shortcut>
          </div>
        ) : null}
      </>
    );
  })
);

const Tooltip = styled.div`
  display: none;
  white-space: nowrap;
`;

const BatchDataBox = styled.div`
  position: fixed;
  top: 20px;
  right: 20px;
  background-color: white;
  padding: 20px;
  border: 2px solid #007bff;
  border-radius: 10px;
  z-index: 1000;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  width: 200px;
  font-size: 1.2rem;
`;

const BatchDataContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`;

const BatchDataItem = styled.div`
  margin-bottom: 10px;
`;

const BatchDataLabel = styled.span`
  font-weight: bold;
  margin-right: 5px;
`;

const BatchDataValue = styled.span`
  color: #007bff;
`;

const Shortcut = styled.div`
  &:hover ${Tooltip} {
    display: flex;
  }
`;
function TranscriptionNotifications({ notifications, isTranscribing }) {
  const [currentNotification, setCurrentNotification] = useState(null);

  useEffect(() => {
    if (notifications.length > 0) {
      const latestNotification = notifications[notifications.length - 1];
      setCurrentNotification(latestNotification);

      if (latestNotification.type === "transcriptionEnd") {
        const timer = setTimeout(() => {
          setCurrentNotification(null);
        }, 15000);

        return () => {
          clearTimeout(timer);
        };
      }
    }
  }, [notifications]);

  if (!currentNotification && !isTranscribing) {
    return null;
  }

  return (
    <div className="fixed top-0 right-0 m-4 z-50">
      <div className="bg-blue-500 text-white p-4 rounded shadow-lg mb-2 flex items-center">
        <div className="mr-2">
          {isTranscribing ? (
            <svg className="h-6 w-6 animate-spin" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
              <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
          ) : (
            <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
              <path
                fillRule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                clipRule="evenodd"
              />
            </svg>
          )}
        </div>
        <div>
          {isTranscribing
            ? "Transcription in progress. Please wait..."
            : currentNotification?.type === "transcriptionEnd"
            ? "Transcription has ended. Marking underway."
            : ""}
        </div>
      </div>
    </div>
  );
}
function Outputs({ outputs, outputsColor, OutputsIcon }) {
  return (
    <div className="whitespace-pre-wrap min-w-full py-4 text-gray-800 h-auto text-lg divide-y">
      <Typist
        stdTypingDelay={0}
        avgTypingDelay={7}
        className="divide-y"
        cursor={{
          show: false,
          blink: false,
          element: "|",
          hideWhenDone: true,
          hideWhenDoneDelay: 250,
        }}
      >
        {outputs.map((output, index) => (
          <div className="py-2 flex items-start" key={index}>
            <div
              className={`mr-4 flex-shrink-0 inline-flex items-center justify-center text-sm h-6 w-6 rounded-full bg-${
                outputsColor ? outputsColor : "green"
              }-200 text-${outputsColor ? outputsColor : "green"}-600`}
            >
              {OutputsIcon === false ? (
                `${index + 1}`
              ) : OutputsIcon ? (
                <OutputsIcon className={`h-4 w-4 text-${outputsColor ? outputsColor : "green"}-600`} aria-hidden="true" />
              ) : (
                <CheckIcon className={`h-4 w-4 text-${outputsColor ? outputsColor : "green"}-600`} aria-hidden="true" />
              )}
            </div>
            {output}
          </div>
        ))}
      </Typist>
    </div>
  );
}
function ErrorNotification({ error }) {
  if (!error) return null;

  return (
    <div className="fixed top-0 left-0 m-4 z-50">
      <div className="bg-red-500 text-white p-4 rounded shadow-lg mb-2 flex items-center">
        <div className="mr-2">
          <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
            <path
              fillRule="evenodd"
              d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
              clipRule="evenodd"
            />
          </svg>
        </div>
        <div>{error}</div>
      </div>
    </div>
  );
}

export default Output;
